import React, {useEffect, useState} from "react";

import { NavLink} from "react-router-dom";
import { Row, Col } from 'reactstrap'

 import Logo from './../../../Assets/Images/logo.png';
 import Emails from './../../../Assets/Images/verified.png';

import LoginSlider from "../../../Components/LoginSlider";
import { checkMobileWebView } from "../../../utility/helper";

  
function PasswordChanged() {
    
  
    return (
      <React.Fragment>
        <div className="container-fluid p-0 signup-page">
        <Row className="m-0">
            <Col className="p-0" lg={6}>
              
             <LoginSlider />
            </Col>
            <Col className="pt-3 signupScreen" lg={6}>
            
                <div className="signup-wrap ">
                  {/* Logo  */}
                  <div className="text-center pb-5 mt-3" data-aos="zoom-in-up" data-aos-delay="100" data-aos-duration="100">
                                                <a 
                                // href= {checkMobileWebView()? "#" :"/"}
                                // onClick={(e)=>{
                                //     if(checkMobileWebView()){
                                //         e.preventDefault();
                                //         window.ReactNativeWebView.postMessage('formSubmitted');
                                //     }
                                // }}
                                href= {"/"}
                            ><img className="mb-4 pb-1" src={Logo} alt="" /></a>
                    <div>
                      <img className="mb-4 " src={Emails} alt="" />
                    </div>
                    <h5 className="mb-3">Password Changed</h5>
                   <p>your password has been successfully changed.</p>
                    <div className="mt-4 pt-0 pt-md-2">
                      <a href="/login" className="btn btn-primary w-100">Login</a> 
                    </div>
                  </div>

               
                </div>

                {/* copyright content  */}
                <div class="copyright text-center p-3"><p class="m-0">Copyright 2024. © Intello Tags All Rights Reserved</p></div>
              </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
  export default PasswordChanged;