import React from "react";
import {Col, Row, Container, Card, CardHeader } from 'reactstrap';
import Subtraction from './../../../../Assets/Images/Subtraction 1.jpg';
import Facebook from './../../../../Assets/Images/facebook.png';
import Instagram from './../../../../Assets/Images/insta.png';
import Youtube from './../../../../Assets/Images/youtube.png';
import Logo from './../../../../Assets/Images/logo.png';
import Fbfront from './../../../../Assets/Images/post/fb-front.png';
import SectionTitle from "../../../../Components/SectionTitle";
import { FacebookEmbed, YouTubeEmbed, InstagramEmbed} from 'react-social-media-embed';

// Community data 
const Community = [
    {
        feed: <div className="feed-block">
            {/* <FacebookEmbed
                url="https://fb.watch/nQZO2qjS-a/"
                width="100%"
                /> */}
              
              <iframe className="fb-iframe-web" src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FINTELLOTAGSS%2F&tabs=timeline&width=420&height=350&small_header=true&adapt_container_width=true&hide_cover=true&show_facepile=true&appId" width="100%" height="350"  scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>

              <img 
                onClick={()=>{
                    window.open('https://www.facebook.com/IntelloTagss/', '_blank');
                }}
               className="fb-iframe-mobile" src={Fbfront} alt="" />

               {/* <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FIntelloTagss%3Fmibextid%3DZbWKwL&tabs=timeline&small_header=false&adapt_container_width=false&hide_cover=false&show_facepile=true&appId" width="100%" height={350} frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe> */}
        </div>, 
        title: 'Facebook'
    },
    {
        feed: <iframe width="100%" height="350" src="https://www.youtube.com/embed/tWMMCn3w4uY?si=AwiYhy-Z6POtZtaC" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>, 
        title: 'YouTube'
    },
    {
        feed: <div className="feed-block">
            {/* <InstagramEmbed url="https://www.instagram.com/p/CRsciWLrpOI/?utm_source=ig_embed&ig_rid=181353fd-2cc5-4aa3-a065-9e0b81129c13" width="100%" /> */}
            <iframe src="https://www.instagram.com/p/CRsciWLrpOI/embed/" width="100%" height="350" frameborder="0" scrolling="yes" allowtransparency="true"></iframe>
        </div>, 
        title: 'Instagram'
    },
];

export default ()=>{

    return (
        <React.Fragment>
            <Container>
                <SectionTitle >
                    <h3 className="section-title" data-aos="zoom-in-up" data-aos-delay="100" data-aos-duration="1000"><span>Intello</span> Community</h3>
                    <div data-aos="zoom-in-up" data-aos-delay="150" data-aos-duration="1000"><span class="sm-title"></span></div>
                </SectionTitle>
                <Row className="text-center">
                {Community
                    .map((item, index) => (
                    <Col md={6} lg={4} key={index} className="" data-aos="zoom-in" data-aos-delay={`${index}00`} data-aos-duration="500">
                        
                        <Card className={`mb-4 ${item.title}`}>
                            <CardHeader className="d-flex align-items-center ps-4 pe-4">
                                <img src={(item.title === 'Facebook' && Facebook) || (item.title === 'YouTube' && Youtube) || (item.title === 'Instagram' && Instagram) } alt={item.title} />
                                <h4 className=" ps-3 m-0">{item.title}</h4>
                            </CardHeader>
                            <div className="p-0">
                                {/* <div className="comm-inner-header pt-3 pb-3 text-start d-flex align-items-center">
                                    <div className="comm-logo"><img className="me-3" src={Logo} alt="Logo" /></div>
                                    <div>
                                        <h6 className="m-0">Intello Tags</h6>
                                        <small><span className="me-1">@intellotag</span><span>05-08-2023</span></small>
                                    </div>
                                </div> */}
                                {/* <a href="#">
                                    <img src={item.feed} alt="Product" className="w-100" />
                                </a> */}
                                {item.feed}
                            </div>
                        </Card>
                    </Col>
                    ))}
                </Row>
            </Container>
        </React.Fragment>
    )
} 