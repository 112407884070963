import React, { useState, useRef, useEffect }  from "react";
import HeaderFooter from "../../HeaderFooter";
import {Col, Row, Breadcrumb, BreadcrumbItem,  Container, Input } from 'reactstrap';
import PriceTable from './../../Components/PriceTable';
import freemeum from './../../Assets/Images/freemium.png';
import Premium from './../../Assets/Images/premium.png';
import Platinium from './../../Assets/Images/platinum.png';
import { checkMobileWebView, getGeoLocationPermission } from "../../utility/helper";
import classNames from 'classnames';
import {useLocation} from "react-router-dom";
import { LoaderForIndividualPageDispatch } from "../../Components/Loader/LoaderForIndividualPage.slice.reducer";
import {useDispatch} from "react-redux"
import { checkQrCodeDetails, checkQRDetailsByQRCodeForReactive } from "../../utility/service";
import { toast } from 'react-toastify';

export default ()=>{
    const dispatch= useDispatch();
    const formRef = useRef(null);
    let { state } = useLocation();
    const freeData = ['Normal Calls', 'Normal Text', 'Normal Email', 'Encrypted Data', 'Normal Tag', '1 Year Validity']
    const premiumData = ['Encrypted Calls', 'Encrypted Text', 'Encrypted Email', 'Encrypted Data', 'Designer Tag', 'DND', 'Scan History', 'Lifetime Discount', 'Tag Replacement', '2.5 Year Validity']
    const platiniumData = ['Encrypted Calls', 'Encrypted Text', 'Encrypted Email', 'Encrypted Data', 'Designer Tag', 'DND', 'Scan History', 'Lifetime Discount', 'Tag Replacement', '5 Year Validity']

    let plans= [
        {price: 50, duration: "30 days validity", checked: true, days: 30},
        {price: 100, duration: "60 days validity", days: 60},
        {price: 150, duration: "90 days validity", days: 90},
        {price: 300, duration: "180 days validity", days: 180},
        {price: 499, duration: "1000 days validity", days: 1000},
    ];

    const [selectedPlan, setSelectedPlan]= useState(plans[0]);
    const [userId, setUserId]= useState('');
    const [qRnumber, setQRnumber]= useState('');
    const [qrType, setQrType]= useState('');

    const getPlansView=(price, duration, checked)=>{
        return<div 
            style={{
                "border": "1px solid #d9d9d9",
                "padding": "5px",
                "border-radius": "7px",
                "display": "flex",
                "justify-content": "space-around",
                marginBottom: '9px',
                cursor: "pointer"
            }}
            onClick={()=>{
                setSelectedPlan(plans.find(plan=> plan.price === price));
            }}
            >
            <span><span style={{
                position: "relative",
                left: "-10px"
            }}><Input 
                    name="priceRadio" 
                    type="radio" 
                    value={price} 
                    // onChange={(e)=>{
                    //     setSelectedPlan(e.target.value)
                    // }}
                    checked= {Number(price)=== Number(selectedPlan.price)}
                />
                </span>{price}</span> 
            <span>{duration}</span>
        </div>
    }

    useEffect(()=>{
        if(state && state.fromExpiredScreen){
            setQRnumber(state.qRnumber);
            getQRinfo(state.qRnumber);
        }
    }, [state]);

    const makePayment=async ()=>{
        console.log("payload 1", selectedPlan,userId,qRnumber)
        if(state && state.fromExpiredScreen){
            formRef.current.submit();
        }else{
            let res= await checkQRDetailsByQRCodeForReactive(qRnumber, dispatch);
            if(res && res.Success && (res.Success.Id <= 0)){
                toast("Please Enter Valid Code")
            }else{
                getQRinfo(qRnumber);
            }
        }
    }

    async function getQRinfo(QRnumber = qRnumber){
        dispatch(LoaderForIndividualPageDispatch(true))
        // setTimeout(() => {
        //     getGeoLocationPermission().then(res=>{
        //         getQrCodeDetails();
        //     }).catch(()=>{
        //         dispatch(LoaderForIndividualPageDispatch(false))
        //         window.location.reload();
        //     });
        // }, 1000);
        async function getQrCodeDetails(){
            let activateData = {
                // latitude: lat,
                // longitude: long,
                latitude: -1,
                longitude: -1,
                qrCode: QRnumber
            }
            try{
                dispatch(LoaderForIndividualPageDispatch(true))
                let res= await checkQrCodeDetails(activateData, dispatch); 
                console.log("dispatchdispatch 1", res)

                if( res && res.Success ){
                    setUserId(res.Success.userId);
                    setQRnumber(res.Success.QRNumber);
                    setQrType(res.Success.QRType)
                    setTimeout(() => {
                        if(state === null){
                            formRef.current.submit();
                            console.log("state.QRNumber 6", userId, qRnumber, qrType, state)
                        }
                    }, 500);
                }
                dispatch(LoaderForIndividualPageDispatch(false))
            }catch (err){
                dispatch(LoaderForIndividualPageDispatch(false))
                if(err!=undefined && err.Error!= undefined && err.Error) toast.error(err.Error.Message, { });
                return;
            }

            // navigator.geolocation.getCurrentPosition( async(position) => {
            //     // let lat = position.coords.latitude;
            //     // let long = position.coords.longitude;
            //     // let activateData = {
            //     //     // latitude: lat,
            //     //     // longitude: long,
            //     //     latitude: -1,
            //     //     longitude: -1,
            //     //     qrCode: QRnumber
            //     // }
            //     // try{
            //     //     dispatch(LoaderForIndividualPageDispatch(true))
            //     //     let res= await checkQrCodeDetails(activateData, dispatch); 
            //     //     console.log("dispatchdispatch 1", res)

            //     //     if( res && res.Success ){
            //     //         setUserId(res.Success.userId);
            //     //         setQRnumber(res.Success.QRNumber);
            //     //         setQrType(res.Success.QRType)
            //     //         setTimeout(() => {
            //     //             if(state === null){
            //     //                 formRef.current.submit();
            //     //                 console.log("state.QRNumber 6", userId, qRnumber, qrType, state)
            //     //             }
            //     //         }, 500);
            //     //     }
            //     //     dispatch(LoaderForIndividualPageDispatch(false))
            //     // }catch (err){
            //     //     dispatch(LoaderForIndividualPageDispatch(false))
            //     //     if(err!=undefined && err.Error!= undefined && err.Error) toast.error(err.Error.Message, { });
            //     //     return;
            //     // }
            // });
        }
        getQrCodeDetails();
    }

    return (
        <React.Fragment>
            <HeaderFooter>
            <main className="main">
                <section className="p-0">
                    <div  className="page-title">
                        <Container>
                            <h2 className="text-center" data-aos-offset="200" data-aos="zoom-in-up" data-aos-delay="100">Membership</h2>
                        </Container>
                    </div>
                </section>
                <section className="pt-4 mt-2 pb-3" data-aos-offset="200" data-aos="fade-in-right" data-aos-delay="100">
                    <Container>
                        <Breadcrumb>
                            <BreadcrumbItem>                            <a 
                                // href= {checkMobileWebView()? "#" :"/"}
                                // onClick={(e)=>{
                                //     if(checkMobileWebView()){
                                //         e.preventDefault();
                                //         window.ReactNativeWebView.postMessage('formSubmitted');
                                //     }
                                // }}
                                href= {"/"}
                            >Home</a></BreadcrumbItem>
                            <BreadcrumbItem active>Membership Plan</BreadcrumbItem>
                        </Breadcrumb>
                    </Container>
                </section>
                <section className="pt-0">
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg={10} >
                                <Row>
                                    <Col sm={6} md={4} lg={4} className="mb-3">
                                        <PriceTable image={Premium} data={premiumData} action="/products" />
                                    </Col>
                                    <Col 
                                        sm={6}
                                        md={7}
                                        lg={7}
                                        className="mb-3"
                                        style={{
                                          display: 'flex',
                                          alignItems: 'flex-end', // Centers vertically
                                          justifyContent: 'center', // Centers horizontally (optional)
                                          height: '100%', // Ensure the Col has a height
                                          flexDirection: "column",
                                          height: "100vh"
                                        }}
                                    >
                                        <Row style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            gap: '14px',
                                        }}>
                                            <Col sm={12} md={10} lg={10} ><h2 style={{
                                                color: "var(--primary__color) !important"
                                            }}>Select Your Renewal Plan</h2></Col>
                                            <Col sm={4} md={7} lg={4} className="mb-3">QR Number</Col>
                                            <Col sm={8} md={7} lg={4}>
                                                <div>
                                                    <input 
                                                        value={qRnumber} 
                                                        readOnly= {(state && state.fromExpiredScreen)? true: false}
                                                        disabled={(state && state.fromExpiredScreen)? true: false}
                                                        className={classNames('form-control')}
                                                        onChange={(e)=> setQRnumber(e.target.value)}

                                                    />
                                                </div>
                                            </Col>
                                            <Col sm={12} md={10} lg={10}  >
                                                {plans.map(plan=> getPlansView(plan.price, plan.duration, plan.checked))}
                                            </Col>
                                            <Col sm={12} md={12} lg={12}
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center"
                                                }}
                                            >
                                                <button 
                                                    type="submit" 
                                                    className="btn btn-primary btn-lg" 
                                                    onClick={()=>{
                                                        makePayment()
                                                    }}
                                                >Pay</button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </main>

             {/* PAYMENT */}
             <form 
                ref={formRef} 
                style={{display: "none"}} 
                method="post" id="myForm" name="myForm" action=" https://admin.intellotags.com/Home/PaymentForReactiveQR" class="ng-scope ng-pristine ng-valid"
            >
                merchant_param1:<input type="text" value={userId} name="userID" fdprocessedid="tlver" />
                merchant_param2:<input type="text" value={qRnumber} name="qrNumber" fdprocessedid="tlvae" />
                merchant_param3:<input type="text" value={selectedPlan.days} name="days" fdprocessedid="tlvio" />
                amount:<input type="text" value={selectedPlan.price} name="amount" fdprocessedid="tlvku" />

                <br />
                <button type="submit" class="btn btn-primary" fdprocessedid="m77oxi">Pay</button>
            </form>   

            </HeaderFooter>
        </React.Fragment>
    )
} 