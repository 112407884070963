import React, {useEffect} from "react";

import { NavLink} from "react-router-dom";
import { Row, Col } from 'reactstrap'

import LoginSlider from './../../../Components/LoginSlider';
 import Logo from './../../../Assets/Images/logo.png';
 import Emails from './../../../Assets/Images/send.png';

 import {useLocation, useNavigate} from "react-router-dom";
import { checkMobileWebView } from "../../../utility/helper";
  
function EmailSent() {

  let { state } = useLocation();
  const navigate = useNavigate()

  useEffect(()=>{
    console.log('this is confirmation props', state)
    if(state === undefined || state === null || state.email === undefined ){
      return navigate('/')
    }
  }, [])
    
  
    return (
      <React.Fragment>
        <div className="container-fluid p-0 signup-page">
        <Row className="m-0">
            <Col className="p-0" lg={6}>
              <LoginSlider />
            </Col>
            <Col className="pt-3 signupScreen" lg={6}>
            
                <div className="signup-wrap " data-aos="zoom-in-up" data-aos-delay="100" data-aos-duration="100">
                  {/* Logo  */}
                  <div className="text-center pb-5 mt-3">
                                                <a 
                                // href= {checkMobileWebView()? "#" :"/"}
                                // onClick={(e)=>{
                                //     if(checkMobileWebView()){
                                //         e.preventDefault();
                                //         window.ReactNativeWebView.postMessage('formSubmitted');
                                //     }
                                // }}
                                href= {"/"}
                            ><img className="mb-4 pb-1" src={Logo} alt="" /></a>
                    <div>
                      <img className="mb-4 " src={Emails} alt="" />
                    </div>
                    <h5 className="mb-4">Email Sent</h5>
                    <p>We sent an email to <a href={`mailto:${state!=undefined && state.email!= undefined && state.email}`} onClick={(e)=>e.preventDefault()}>{state!=undefined && state.email!= undefined && state.email}</a> with a link to reset your password.</p>
                    <div className="pt-2">Back to <NavLink to="/login"><u>Login</u></NavLink></div>
                  </div>


                </div>

                {/* copyright content  */}
                <div class="copyright text-center p-3"><p class="m-0">Copyright 2024. © Intello Tags All Rights Reserved</p></div>
              </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
  export default EmailSent;