import React, { useState, useRef, useEffect }  from "react";
import HeaderFooter from "../../../../HeaderFooter";
import {Col, Row, Input, Container, NavLink } from 'reactstrap';
import PriceTable from './../../../../Components/PriceTable';
import freemeum from './../../../../Assets/Images/freemium.png';
import Premium from './../../../../Assets/Images/premium.png';
import Platinium from './../../../../Assets/Images/platinum.png';
import { checkMobileWebView } from "../../../../utility/helper";
import classNames from 'classnames';
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux"
import TagImg from './../../../../Assets/Images/membership-expired.png'
import App from './../../../../Assets/Images/app.png';
import Play from './../../../../Assets/Images/google-play.png';
import Logo from './../../../../Assets/Images/logo.png';
import { setScrollToOnlineStoreDispatch } from "../../../Home/Slices/scrollManagment.slice";

export default ()=>{

    const dispatch= useDispatch();
    const navigate = useNavigate();
    const formRef = useRef(null);
    let { state } = useLocation();
    const scrollManagment= useSelector(state=>state.scrollManagment);
    const freeData = ['Normal Calls', 'Normal Text', 'Normal Email', 'Encrypted Data', 'Normal Tag', '1 Year Validity']
    const premiumData = ['Encrypted Calls', 'Encrypted Text', 'Encrypted Email', 'Encrypted Data', 'Designer Tag', 'DND', 'Scan History', 'Lifetime Discount', 'Tag Replacement', '3 Year Validity']
    const platiniumData = ['Encrypted Calls', 'Encrypted Text', 'Encrypted Email', 'Encrypted Data', 'Designer Tag', 'DND', 'Scan History', 'Lifetime Discount', 'Tag Replacement', '5 Year Validity']

    let plans= [
        {price: 50, duration: "30 days validity", checked: true, days: 30},
        {price: 100, duration: "60 days validity", days: 60},
        {price: 150, duration: "90 days validity", days: 90},
        {price: 300, duration: "180 days validity", days: 180},
        {price: 499, duration: "1000 days validity", days: 1000},
    ];

    const [selectedPlan, setSelectedPlan]= useState(plans[0]);
    const [userId, setUserId]= useState('');
    const [qRnumber, setQRnumber]= useState('');
    const [qrType, setQrType]= useState('');
    const [payload, setPayload]= useState(null);

    const getPlansView=(price, duration, checked)=>{
        return<div 
            style={{
                "border": "1px solid #d9d9d9",
                "padding": "5px",
                "border-radius": "7px",
                "display": "flex",
                "justify-content": "space-around",
                marginBottom: '9px',
                cursor: "pointer"
            }}
            onClick={()=>{
                setSelectedPlan(plans.find(plan=> plan.price === price));
            }}
            >
            <span><span style={{
                position: "relative",
                left: "-10px"
            }}><Input 
                    name="priceRadio" 
                    type="radio" 
                    value={price} 
                    // onChange={(e)=>{
                    //     setSelectedPlan(e.target.value)
                    // }}
                    checked= {Number(price)=== Number(selectedPlan.price)}
                />
                </span>{price}</span> 
            <span>{duration}</span>
        </div>
    }

    useEffect(()=>{
        if(state.qrDetails && state.qrDetails.Status){
            setUserId(state.qrDetails.Success.userId);
            setQRnumber(state.qrDetails.Success.QRNumber);
            setQrType(state.qrDetails.Success.QRType)
            localStorage.setItem("userId", state.qrDetails.Success.userId);
        }
    }, [state])

    const makePayment= ()=>{
        console.log("payload 1", selectedPlan,userId,qRnumber, state.qrDetails)
        formRef.current.submit();
    }

    // if(qrType !== "Preemium"){
        return <React.Fragment>
        <main className="bg-light qr-screen h-100vh">
            <header className="header  px-3 px-sm-4">
                <div className="container">
                    <nav class="fixed-top1 navbar-expand-lg navbar navbar-light bg-faded">
                        <a 
                            // href= {checkMobileWebView()? "#" :"/"}
                            // onClick={(e)=>{
                            //     if(checkMobileWebView()){
                            //         e.preventDefault();
                            //         window.ReactNativeWebView.postMessage('formSubmitted');
                            //     }
                            // }}
                            href= {"/"}
                        >
                            <img src={Logo} alt="Logo" className="img-fluid mt-0" />
                        </a>
                    </nav>
                </div>
            </header>
            <section>
                <Container>
                    <Row className="justify-content-center mt-3 text-center">
                        <Col xl={6}>
                            <div className="mb-2 text-primary">
                                <h3 className="text-danger">Membership Expired</h3>
                            </div>
                            <div className="membershipExpired py-4">
                                <img src={TagImg} className="img-fluid" alt="Tag" />
                            </div>
                            <div className="mb-5">
                                <p className="font-medium">Renew your membership for<br/>endless possibilities.</p>
                                <div 
                                    style={{
                                        display: "flex",
                                        "justify-content": "center"
                                    }}
                                >
                                {
                                    qrType === "Preemium" ? 
                                    <NavLink 
                                        href="#"
                                        className="btn btn-outline-primary px-lg-4 renew-tag"
                                        onClick={()=>{
                                            navigate('/membership', { state: {
                                                qRnumber,
                                                fromExpiredScreen: true
                                            }});
                                        }}
                                    >Renew Your Tag</NavLink>
                                    : 
                                    <NavLink 
                                        href="#"
                                        className="btn btn-outline-primary px-lg-4"
                                        onClick={()=>{
                                            navigate('/');
                                            dispatch(setScrollToOnlineStoreDispatch(scrollManagment.scrollToOnlineStore+1));
                                        }} 
                                        style={{
                                            width: "27%",
                                            padding: "9px"
                                        }}
                                    >Buy Now</NavLink>
                                }
                                </div>
                            </div>
                            <div className="footer-apps">
                                <h5 className="mb-2">Download our App</h5>
                                <a href="#" className="w-100 me-0" target="_blank">
                                    <img src={App} className="img-fluid" alt="App Store" />
                                </a><br/>
                                <a  className="w-100 ms-0" href="https://play.google.com/store/apps/details?id=com.intello.tagss&pli=1" target="_blank">
                                    <img src={Play} className="img-fluid" alt="Play Store" />
                                </a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </main>
        </React.Fragment>
    // }

    return (
        <React.Fragment>
            <main className="main">
                <section className="p-0">
                    <div  className="page-title">
                        <Container>
                            <h2 className="text-center" data-aos-offset="200" data-aos="zoom-in-up" data-aos-delay="100">Membership Plan</h2>
                        </Container>
                    </div>
                </section>
                <section className="pt-0">
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg={10} >
                                <Row>
                                    <Col sm={6} md={4} lg={4} className="mb-3">
                                        <PriceTable image={Premium} data={premiumData} action="/products" />
                                    </Col>

                                    <Col 
                                        sm={6}
                                        md={7}
                                        lg={7}
                                        className="mb-3"
                                        style={{
                                          display: 'flex',
                                          alignItems: 'flex-end', // Centers vertically
                                          justifyContent: 'center', // Centers horizontally (optional)
                                          height: '100%', // Ensure the Col has a height
                                          flexDirection: "column",
                                          height: "100vh"
                                        }}
                                    >
                                        <Row style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            gap: '14px',
                                        }}>
                                            <Col sm={12} md={10} lg={10} ><h2 style={{
                                                color: "var(--primary__color) !important"
                                            }}>Select Your Renewal Plan</h2></Col>
                                            <Col sm={4} md={7} lg={4} className="mb-3">QR Number</Col>
                                            <Col sm={8} md={7} lg={4} className="mb-3">
                                                <div className="mb-3">
                                                    <input 
                                                        value={qRnumber} 
                                                        readOnly
                                                        disabled
                                                        className={classNames('form-control')}
                                                    />
                                                </div>
                                            </Col>
                                            <Col sm={12} md={10} lg={10} className="mb-3" >
                                                {plans.map(plan=> getPlansView(plan.price, plan.duration, plan.checked))}
                                            </Col>
                                            <Col sm={5} md={3} lg={3} className="mb-3" >
                                                <div className="mt-2">
                                                    <button 
                                                        type="submit" 
                                                        className="btn btn-primary mt-4 btn-lg" 
                                                        onClick={()=>{
                                                            makePayment()
                                                        }}
                                                    >Pay</button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </main>    
            {/* PAYMENT */}
            <form 
                ref={formRef} 
                style={{display: "none"}} 
                method="post" id="myForm" name="myForm" action=" https://admin.intellotags.com/Home/PaymentForReactiveQR" class="ng-scope ng-pristine ng-valid"
            >
                merchant_param1:<input type="text" value={userId} name="userID" fdprocessedid="tlver" />
                merchant_param2:<input type="text" value={qRnumber} name="qrNumber" fdprocessedid="tlvae" />
                merchant_param3:<input type="text" value={selectedPlan.days} name="days" fdprocessedid="tlvio" />
                amount:<input type="text" value={selectedPlan.price} name="amount" fdprocessedid="tlvku" />

                <br />
                <button type="submit" class="btn btn-primary" fdprocessedid="m77oxi">Pay</button>
            </form>


        </React.Fragment>
    )
} 