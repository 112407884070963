import React, { useEffect, useState } from "react";
import { Row, Col, Breadcrumb, BreadcrumbItem,FormGroup, Label, Input, Table, Modal, ModalHeader, ModalBody,ModalFooter, Button } from 'reactstrap';
import { useLocation, NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import { fetchActivateTagDetails, fetchDocuments, fetchLastScanLocations, postDocument, postSaveDND, postUpdateUserDetailsPerQR, putDocument } from "../../../utility/service";
import validator from 'validator';
import { ImageCompressor } from 'image-compressor';
import { checkMobileWebView } from "../../../utility/helper";
import { LoaderForIndividualPageDispatch } from "../../../Components/Loader/LoaderForIndividualPage.slice.reducer";

const imageCompressor = new ImageCompressor;
const compressorSettings = {
    toWidth : 800,
    // toHeight : 500,
    mimeType : 'image/png',
    mode : 'strict',
    quality : 0.9,
    // grayScale : true,
    // sepia : true,
    // threshold : 127,
    vReverse : false,
    hReverse : false,
    speed : 'low'
};

function EditTag(props) {

    const navigate = useNavigate()
    const dispatch= useDispatch();
    const {state} = useLocation();

    console.log("propsprops", props)

    const profile= useSelector(state=>state.profile).details;
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [gender, setGender] = useState("")
    const [contactNumber, setContactNumber] = useState("")
    const [emergenciesContactNumber, setEmergenciesContactNumber] = useState("")
    const [address, setAddress] = useState("");
    const [blodGroup, setBlodGrop] = useState("");
    const [elagiesComent, setElagiesComent] = useState("");
    const [dob, setDob] = useState("")
    const [docView, setDocView] = useState({show: false, url: ""})
    
    const [documentOne, setDocumentOne] = useState({
        DocumentType: "",
        DocumentTypeID: "",
        ExpiredDate: "",
        FileName: "",
        QRID: props.tagData.QRNumber,
        actualFileName: ""
    });
    const [documentTwo, setDocumentTwo] = useState({
        DocumentType: "",
        DocumentTypeID: "",
        ExpiredDate: "",
        FileName: "",
        QRID: props.tagData.QRNumber,
        actualFileName: ""
    });
    const [documentThree, setDocumentThree] = useState({
        DocumentType: "",
        DocumentTypeID: "",
        ExpiredDate: "",
        FileName: "",
        QRID: props.tagData.QRNumber,
        actualFileName: ""
    });
    
    const [firstNameErr, setrFirstNameErr] = useState(false)
    const [lastNameErr, setLastNameErr] = useState(false)
    const [emailNameErr, setEmailNameErr] = useState(false)
    const [genderErr, setGenderErr] = useState(false)
    const [contactNumberErr, setContactNumberErr] = useState(false)
    const [emergenciesContactNumberErr, setEmergenciesContactNumberErr] = useState(false)
    const [addressErr, setAddressErr] = useState(false)
    const [blodGroupErr, setBlodGropErr] = useState(false);
    const [elagiesComentErr, setElagiesComentErr] = useState(false);
    const [dobErr, setDobErr] = useState(false)
    
    const [DNDcall, setDNDcall] = useState(false)
    // const [DNDwhatsapp, setDNDwhatsapp] = useState(false)
    const [DNDMedicalHistory, setDNDmedicalHistory] = useState(false)
    const [DNDemail, setDNDemail] = useState(false)
    const [DNDemergencycall, setDNDemergencycall] = useState(false)
    
    const [lastLocationLists, setLastLocationLists] = useState([])
    const [callApiAgain, setCallApiAgain] = useState(1)

    async function loadPageData(){
        async function getActivateTags(){
            try{
                console.log('this is try registration', "try")
                let res= await fetchActivateTagDetails({qrCode: props.tagData.QRNumber}, dispatch); 

                if(res && res.Success ){
                    
                    setFirstName(res.Success.FirstName)
                    setLastName(res.Success.LastName)
                    setEmail(res.Success.Email)
                    setContactNumber(res.Success.Contact_No)
                    setEmergenciesContactNumber(res.Success.EmergencyNumber)
                    setAddress(res.Success.Address)
                    setBlodGrop(res.Success.Blood_Group)
                    setElagiesComent(res.Success.Allergies_Comment)

                    setDNDcall(res.Success.DNDForCall);
                    setDNDemail(res.Success.DNDForEmail);
                    setDNDemergencycall(res.Success.DNDForEnargencyCall);
                    setDNDmedicalHistory(res.Success.DNDMedicalHistory);
                    // setDNDwhatsapp(res.Success.DNDForEnargencyCall);


                    if(res.Success.DOB){
                        const parts = res.Success.DOB.split('/');
                        let actualDate = `${parts[2]}-${parts[0].padStart(2, '0')}-${parts[1].padStart(2, '0')}`;
                        setDob(actualDate)
                    }
                    if(res.Success.Gender===  "M")
                    setGender("M")
                    else if(res.Success.Gender===  "F")
                    setGender("F")
                    else if(res.Success.Gender===  "O")
                    setGender("O")
                }


            }catch (err){
                if(err!=undefined && err.Error!= undefined && err.Error) toast.error(err.Error.Message, { });
                
            }
        }
        async function getDocuments(){
            try{
                
                let res= await fetchDocuments({qrCode: props.tagData.QRNumber}, dispatch); 

                if(res.Success && res.Success.PagedList && res.Success.PagedList.length >0){
                    res.Success.PagedList.map((file, index)=>{
                        if(index === 0){
                           
                            setDocumentOne({
                                ...documentOne,
                                ExpiredDate: file.ExpiredDate.split('T')[0],
                                // ExpiredDate: file.ExpiredDate,
                                DocumentTypeID: String(file.DocumentTypeID),
                                DocumentType: file.DocumentType,
                                FileName: file.FileName,
                                actualFileName: file.FileName,
                                ID: file.ID
                            })
                        }
                        if(index === 1) {
                           
                            setDocumentTwo({
                                ...documentTwo,
                                ExpiredDate: file.ExpiredDate.split('T')[0],
                                // ExpiredDate: file.ExpiredDate,
                                DocumentTypeID: String(file.DocumentTypeID),
                                DocumentType: file.DocumentType,
                                FileName: file.FileName,
                                actualFileName: file.FileName,
                                ID: file.ID
                            })
                        }
                        if(index === 2){ 
                            
                            setDocumentThree({
                                ...documentTwo,
                                ExpiredDate: file.ExpiredDate.split('T')[0],
                                // ExpiredDate: file.ExpiredDate,
                                DocumentTypeID: String(file.DocumentTypeID),
                                DocumentType: file.DocumentType,
                                FileName: file.FileName,
                                actualFileName: file.FileName,
                                ID: file.ID
                            })
                        }
                    })
                }
            }catch (err){
                console.log('this is try registration', err)
                if(err!=undefined && err.Error!= undefined && err.Error) toast.error(err.Error.Message, { });
            }
        }
        async function getLastLocation(){
            try{
                console.log('this is try registration', "try")
                let res= await fetchLastScanLocations({qrCode: props.tagData.QRNumber}, dispatch); 
                if(res.Success && res.Success && res.Success.length >0){
                    setLastLocationLists(res.Success);
                }
            }catch (err){
                if(err!=undefined && err.Error!= undefined && err.Error) toast.error(err.Error.Message, { });
                
            }
        }
        getActivateTags();
        getDocuments();
        getLastLocation();
    }
    
    useEffect(()=>{
        loadPageData();
    }, [profile]);

    useEffect(()=>{

        async function setLocalStorage(){
            await localStorage.removeItem("editTagScreen");
            await localStorage.setItem("editTagScreen", JSON.stringify({...props.tagData}))
        }
        setLocalStorage()
        return ()=>{
            localStorage.removeItem("editTagScreen");
        }
    }, [])

    const updateActiveTag= async(e)=>{

        e.preventDefault();
        toast.dismiss();

        if(firstName!== undefined && validator.isEmpty(firstName.trim())){
            toast.error("Please Enter First Name", { });
            setrFirstNameErr(true)
            return;
        }

        if(firstName!== undefined && validator.isEmpty(firstName.trim())){
            toast.error("Please Enter First Name", { });
            setrFirstNameErr(true)
            return;
        }else if(firstName!== undefined && !validator.isLength(firstName.trim(), {min: 1, max: 25})){
            toast.error("First Name length should be minimum 3 char and maximum 25 char", { });
            setrFirstNameErr(true)
            return;
        }
         
        if(lastName!== undefined && validator.isEmpty(lastName.trim())){
            toast.error("Please Enter Last Name", { });
            setLastNameErr(true)
            return;
        }else if(lastName!== undefined && !validator.isLength(lastName.trim(), {min: 1, max: 25})){
            toast.error("Last Name length should be minimum 3 char and maximum 25 char", { });
            setLastNameErr(true)
            return;
        }

        if(!validator.isEmail(email, {blacklisted_chars : "+-"})){
            if(email === "") toast.error("Please Enter Email", { });
            else toast.error("Please Enter valid Email", { });
            setEmailNameErr(true)
            return
        }

        if(validator.isEmpty(gender)){
            toast.error("Please Select gender", { });
            setGenderErr(true)
            return;
        }

        if(contactNumber ===null ||validator.isEmpty(contactNumber)){
            toast.error("Please Enter emergency Number", { });
            setContactNumberErr(true)
            return;
        }

        if(emergenciesContactNumber ===null ||validator.isEmpty(emergenciesContactNumber)){
            toast.error("Please Enter emergency Number", { });
            setEmergenciesContactNumberErr(true)
            return;
        }

        if(validator.isEmpty(address.trim())){
            toast.error("Please Enter address Name", { });
            setAddressErr(true)
            return;
        } 

        if(blodGroup!== undefined && validator.isEmpty(blodGroup.trim())){
            toast.error("Please Enter Blod Group Name", { });
            setBlodGropErr(true)
            return;
        }
      
        if(elagiesComent!== undefined && validator.isEmpty(elagiesComent.trim())){
            toast.error("Please Allergies / Comment", { });
            setElagiesComentErr(true)
            return;
        }

        if(validator.isEmpty(dob)){
            toast.error("Please Enter dob", { });
            setDobErr(true)
            return;
        }

        console.log("documentOnedocumentOne", documentOne)

        let allDoc= {
            docOne: false,
            docTwo: false,
            docThree: false
        }
        
        if(!documentOne.DocumentTypeID && !documentOne.ExpiredDate && !documentOne.FileName){
            allDoc.docOne= true;
        }else if(!documentOne.DocumentTypeID || !documentOne.ExpiredDate || !documentOne.FileName || !documentOne.QRID ){
            toast.error("Please Select All Required Field For Document 1", { });
            return;
        } 

        // alert(!documentTwo.DocumentTypeID)

        if(!documentTwo.DocumentTypeID && !documentTwo.ExpiredDate && !documentTwo.FileName){
            allDoc.docTwo= true;
        }else if(!documentTwo.DocumentTypeID || !documentTwo.ExpiredDate || !documentTwo.FileName || !documentTwo.QRID ){
            toast.error("Please Select All Required Field For Document 2", { });
            return;
        } 

        if(!documentThree.DocumentTypeID && !documentThree.ExpiredDate && !documentThree.FileName){
            allDoc.docThree= true;
        }else if(!documentThree.DocumentTypeID || !documentThree.ExpiredDate || !documentThree.FileName || !documentThree.QRID ){
            toast.error("Please Select All Required Field For Document 3", { });
            return;
        }

        let updateActivateData = {
            FirstName: firstName,
            LastName: lastName,
            Email: email,
            Gender: gender,
            Contact_No: contactNumber,
            EmergencyNumber: emergenciesContactNumber,
            PhoneNumber: "",
            Address: address,
            Blood_Group: blodGroup,
            Allergies_Comment: elagiesComent,
            DOB: dob,
            // documentOne: documentOne,
            // documentTwo: documentTwo,
            // documentThree: documentThree,
            QRId: props.tagData.QRNumber
          } 
          
        try{
            
            // let ress =await converToBlob(documentOne.FileName)
            // console.log("ressress", ress)
            
            let res= await postUpdateUserDetailsPerQR(updateActivateData, dispatch); 

            let newDocArr =[]
                
            if(!allDoc.docOne){
                newDocArr.push(documentOne)
                // await putDocument(documentOne, dispatch); 
            }
            if(!allDoc.docTwo){
                newDocArr.push(documentTwo)
                // await putDocument(documentTwo, dispatch); 
            }
            if(!allDoc.docThree){
                newDocArr.push(documentThree)
                // await putDocument(documentThree, dispatch); 
            }
            await postDocument(newDocArr, dispatch); 
            loadPageData();
            setCallApiAgain(callApiAgain+1);
            toast("Tag Updated Successfully", { });

            // if(checkMobileWebView()){
            //     window.ReactNativeWebView.postMessage('formSubmitted');
            // }

            navigate('/');
        }catch (err){
            if(err!=undefined && err.Error!= undefined && err.Error) toast.error(err.Error.Message, { });
            
        }
    }

    const converToBlob = async (imgSrc) =>{

       return await fetch(imgSrc)
        .then(response => response.blob())
        .then(blob => {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          return new Promise((res) => {
            reader.onloadend = () => {
            res(reader.result);
          }})
        }) 
    }

    const handleImageUpload = (event) => {

        const file = event.target.files[0];

        if(file && file.size && ((file.size / (1024 * 1024)) > 10)){
            toast.dismiss();
            toast("File size limited to 10MB");
            return;
        }
        
        if (file) {
            const reader = new FileReader();
            
            reader.onload = (e) => {
                
                const base64String = e.target.result;

                if(event.target.name === "documentOne"){
                    if(file && file.size && ((file.size / (1024 * 1024)) > 2)){
                        setDocumentOne((pre)=>{
                            return{
                                ...pre,
                                actualFileName: file.name
                            }
                        })
                        imageCompressor.run(base64String, compressorSettings, proceedCompressedImageDocumentOne);
                    }else{
                        setDocumentOne(pre=>{
                            return{
                                ...pre,
                                FileName: base64String,
                                actualFileName: file.name
                            }
                        })
                    }
                }
                else if(event.target.name === "documentTwo"){
                    if(file && file.size && ((file.size / (1024 * 1024)) > 2)){
                        setDocumentTwo((pre)=>{
                            return{
                                ...pre,
                                actualFileName: file.name
                            }
                        })
                        imageCompressor.run(base64String, compressorSettings, proceedCompressedImageDocumentTwo);
                    }else{
                        setDocumentTwo(pre=>{
                            return{
                                ...pre,
                                FileName: base64String,
                                actualFileName: file.name
                            }
                        })
                    }
                }
                else if(event.target.name === "documentThree"){
                    if(file && file.size && ((file.size / (1024 * 1024)) > 2)){
                        setDocumentThree((pre)=>{
                            return{
                                ...pre,
                                actualFileName: file.name
                            }
                        })
                        imageCompressor.run(base64String, compressorSettings, proceedCompressedImageDocumentThree);
                    }else{
                        setDocumentThree(pre=>{
                            return{
                                ...pre,
                                FileName: base64String,
                                actualFileName: file.name
                            }
                        })
                    }
                }
            };
            
            reader.readAsDataURL(file);
        }
    };  

    const proceedCompressedImageDocumentOne = (compressedSrc) => {
        setDocumentOne(pre=>{
            return{
                ...pre,
                FileName: compressedSrc
            }
        })
    }
    const proceedCompressedImageDocumentTwo = (compressedSrc) => {
        setDocumentTwo(pre=>{
            return{
                ...pre,
                FileName: compressedSrc
            }
        })
    }
    const proceedCompressedImageDocumentThree = (compressedSrc) => {
        setDocumentThree(pre=>{
            return{
                ...pre,
                FileName: compressedSrc
            }
        })
    }

    const onInputChange = (e)=>{
    
        switch(e.target.name){
            case "firstName":
                setFirstName(e.target.value.replace(/[^a-z]/gi, ''));
                setrFirstNameErr(false)
            break;
            case "lastName":
                setLastName(e.target.value.replace(/[^a-z]/gi, ''));
                setLastNameErr(false)
            break;
            case "email":
                setEmail(e.target.value);
                setEmailNameErr(false)
            break;
            case "contact-number":
                if(/^(?:\+?\d{0,15}|\d{0,16})$/.test((e.target.value))){
                    setContactNumber(e.target.value);
                    setContactNumberErr(false);
                }
            break;
            case "emergency-contact":
                if(/^(?:\+?\d{0,15}|\d{0,16})$/.test((e.target.value))){
                    setEmergenciesContactNumber(e.target.value);
                    setEmergenciesContactNumberErr(false);
                }
            break;
            case "address":
                setAddress(e.target.value);
                setAddressErr(false)
            break;
            case "allergies":
                setElagiesComent(e.target.value)
                setElagiesComentErr(false);
            break;
            case "blood-group":
                setBlodGrop(e.target.value)
                setBlodGropErr(false);
            break;
        }
    }
    
    const selectedValue = (e) =>{
        
        switch(e.target.name){
            case "gender":
                setGender(e.target.value);
                setGenderErr(false);
            break;
            case "docType1":
                setDocumentOne({...documentOne, DocumentTypeID: e.target.value, DocumentType: getDocumentTypeTextValue(e.target.value)})
            break;
            case "docType2":
                setDocumentTwo({...documentTwo, DocumentTypeID: e.target.value, DocumentType: getDocumentTypeTextValue(e.target.value)})
            break;
            case "docType3":
                setDocumentThree({...documentThree, DocumentTypeID: e.target.value, DocumentType: getDocumentTypeTextValue(e.target.value)})
            break;
          }
    }

    const onDateChangeHandler = (e) =>{
        switch(e.target.name){
            case "personaldob":
                setDob(e.target.value);
                setDobErr(false);
            break;
            case "docType1expiryData":
                setDocumentOne({...documentOne, ExpiredDate: e.target.value});
                break;
            case "docType2expiryData":
                setDocumentTwo({...documentTwo, ExpiredDate: e.target.value});
            break;
            case "docType3expiryData":
                setDocumentThree({...documentThree, ExpiredDate: e.target.value});
            break;
        }
    }

    const getDocumentTypeTextValue= (val)=>{

        if(val == 1){
            return "Vehicle Insurance"
        }else if(val == 2){
            return "Vehicle Registration"
        }else if(val == 3){
            return "PUC Certificate"
        }else if(val == 4){
            return "Driving License"
        }else if(val == 5){
            return "Residence proof"
        }else if(val == 6){
            return "Id proof"
        }

    }

    const getGenderView = (e) =>{
        return <><option value= "" selected= {gender !== ""? true: false}>Select gender</option>
            <option value= "M" selected= {gender === "M"? true: false}>Male</option>
            <option value="F" selected= {gender === "F"? true: false}>Female</option>
            <option value="O" selected= {gender === "O"? true: false}>Other</option></>
    }

    const getDocumentsTypeViewForDocType1 = (e) =>{
        return <><option value= "" selected= {documentOne.DocumentTypeID == ""? true: false}>Select Document type</option>
        <option value="1" selected= {documentOne.DocumentTypeID == "1"? true: false}>Vehicle Insurance</option>
        <option value="2" selected= {documentOne.DocumentTypeID == "2"? true: false}>Vehicle Registration</option>
        <option value="3" selected= {documentOne.DocumentTypeID == "3"? true: false}>PUC Certificate</option>
        <option value="4" selected= {documentOne.DocumentTypeID == "4"? true: false}>Driving License</option>
        <option value="5" selected= {documentOne.DocumentTypeID == "5"? true: false}>Residence proof</option>
        <option value="6" selected= {documentOne.DocumentTypeID == "6"? true: false}>Id proof</option></>
    }
    const getDocumentsTypeViewForDocType2 = (e) =>{
        return <><option value= "" selected= {documentTwo.DocumentTypeID == ""? true: false}>Select Document type</option>
        <option value="1" selected= {documentTwo.DocumentTypeID == "1"? true: false}>Vehicle Insurance</option>
        <option value="2" selected= {documentTwo.DocumentTypeID == "2"? true: false}>Vehicle Registration</option>
        <option value="3" selected= {documentTwo.DocumentTypeID == "3"? true: false}>PUC Certificate</option>
        <option value="4" selected= {documentTwo.DocumentTypeID == "4"? true: false}>Driving License</option>
        <option value="5" selected= {documentOne.DocumentTypeID == "5"? true: false}>Residence proof</option>
        <option value="6" selected= {documentOne.DocumentTypeID == "6"? true: false}>Id proof</option></>
    }
    const getDocumentsTypeViewForDocType3 = (e) =>{
        return <><option value= "" selected= {documentThree.DocumentTypeID == ""? true: false}>Select Document type</option>
        <option value="1" selected= {documentThree.DocumentTypeID == "1"? true: false}>Vehicle Insurance</option>
        <option value="2" selected= {documentThree.DocumentTypeID == "2"? true: false}>Vehicle Registration</option>
        <option value="3" selected= {documentThree.DocumentTypeID == "3"? true: false}>PUC Certificate</option>
        <option value="4" selected= {documentThree.DocumentTypeID == "4"? true: false}>Driving License</option>
        <option value="5" selected= {documentOne.DocumentTypeID == "5"? true: false}>Residence proof</option>
        <option value="6" selected= {documentOne.DocumentTypeID == "6"? true: false}>Id proof</option></>
    }

    function formatDateIndianStandard(dateString) {
        const date = new Date(dateString); // Create a Date object from the given date string
        const options = { 
          day: 'numeric', 
          month: 'numeric', 
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric'
        };
        const formattedDate = date.toLocaleString('en-IN', options); // Format the date in Indian standard
        return formattedDate;
    }

    function formatTimeIndianStandard(dateString) {
        const date = new Date(dateString); // Create a Date object from the given date string
        const options = { 
          hour: 'numeric',
          minute: 'numeric',
          hour12: true
        };
        const formattedTime = date.toLocaleString('en-IN', options); // Format the time in Indian standard
        return formattedTime;
    }

    console.log("documentThree.FileName", documentThree.FileName)

    const getLocaionListView = (e) =>{
        return lastLocationLists.map(location =>{
            return <tr>
            <td>{formatDateIndianStandard(location.CreatedDate)}</td>
            <td>{formatTimeIndianStandard(location.CreatedDate)}</td>
            <td>
                {location.Lat},{location.Long}{" "}
                <NavLink 
                    to={`https://www.google.com/maps/?q=${location.Lat} ,${location.Long}`}
                    target="_blank"><u>Find on map! </u>
                </NavLink> 
            </td>
        </tr>
           
        })
    }

    // useEffect(()=>{
    //     async function toggleDndCall(){
    //         try{
    //             let callDndData={
    //                 DND: DNDcall,
    //                 QRId: props.tagData.QRNumber,
    //                 ForValue: 1
    //             }
    //             await postSaveDND(callDndData, dispatch); 
    //         }catch (err){
    //             if(err!=undefined && err.Error!= undefined && err.Error) toast.error(err.Error.Message, { });
                
    //         }
    //     }
    //     toggleDndCall()
    // }, [DNDcall])
   
    // useEffect(()=>{
    //     async function toggleDndCall(){
    //         try{
    //             let callDndData={
    //                 DND: DNDwhatsapp,
    //                 QRId: props.tagData.QRNumber,
    //                 ForValue: 2
    //             }
    //             await postSaveDND(callDndData, dispatch); 
    //         }catch (err){
    //             if(err!=undefined && err.Error!= undefined && err.Error) toast.error(err.Error.Message, { });
                
    //         }
    //     }
    //     toggleDndCall()
    // }, [DNDwhatsapp])
   
    // useEffect(()=>{
    //     async function toggleDndCall(){
    //         try{
    //             let callDndData={
    //                 DND: DNDemail,
    //                 QRId: props.tagData.QRNumber,
    //                 ForValue: 3
    //             }
    //             await postSaveDND(callDndData, dispatch); 
    //         }catch (err){
    //             if(err!=undefined && err.Error!= undefined && err.Error) toast.error(err.Error.Message, { });
                
    //         }
    //     }
    //     toggleDndCall()
    // }, [DNDemail])
  
    // useEffect(()=>{
    //     async function toggleDndCall(){
    //         try{
    //             let callDndData={
    //                 DND: DNDemergencycall,
    //                 QRId: props.tagData.QRNumber,
    //                 ForValue: 4
    //             }
    //             await postSaveDND(callDndData, dispatch); 
    //         }catch (err){
    //             if(err!=undefined && err.Error!= undefined && err.Error) toast.error(err.Error.Message, { });
                
    //         }
    //     }
    //     toggleDndCall()
    // }, [DNDemergencycall])

    const showDocview = ()=>{
        console.log('documentOne.FileName', documentOne.FileName)
        console.log('documentTwo.FileName', documentTwo.FileName)
        console.log('documentThree.FileName', documentThree.FileName)
        return  <div>
            <Modal 
                isOpen={docView.show} 
                toggle={()=>{
                    setDocView(()=> ({show: false, url: ""}));
                }} 
                className="modal-lg inner-btn-modal"
            >
                <ModalBody>
                    <img 
                        src={docView.url} 
                        alt="docView.url"
                        style={{
                            "height": "100%",
                            "width": "100%",
                            "object-fit": "cover",
                        }}
                        onLoad={()=>{
                            dispatch(LoaderForIndividualPageDispatch(false))
                        }}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={()=>{
                        setDocView(()=> ({show: false, url: ""}));
                    }}>Close</Button>
                </ModalFooter>
            </Modal>
        </div>
    }

    return (
        <React.Fragment>
            <div className="edit-tag ps-0 pt-2 pe-0 pb-2 ps-md-3 pe-md-3">
                {showDocview()}
                <Breadcrumb>
                    <BreadcrumbItem>                            <a 
                                // href= {checkMobileWebView()? "#" :"/"}
                                // onClick={(e)=>{
                                //     if(checkMobileWebView()){
                                //         e.preventDefault();
                                //         window.ReactNativeWebView.postMessage('formSubmitted');
                                //     }
                                // }}
                                href= {"/"}
                            >Home</a></BreadcrumbItem>
                    <BreadcrumbItem>
                    <a href="#" 
                        onClick={(e)=>{
                            e.preventDefault();
                            props.onSetIsEdit();
                        }}>Activated tag
                    </a>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>Tag Details</BreadcrumbItem>
                </Breadcrumb>
                
                <form className="pt-1" onSubmit={updateActiveTag}>
                    <div className="border-box">
                        <Row className="">
                            <Col xxl={10}>
                                <h6>Personal details</h6>
                                <Row>
                                    <Col className="mb-3" md={6}>
                                        <label>First Name</label>
                                        <input 
                                            type="text" 
                                            className='form-control' 
                                            name="firstName" 
                                            value={firstName}
                                            onChange={onInputChange}
                                            placeholder="Enter first Name"  />
                                    </Col>
                                
                                    <Col className="mb-3" md={6}>
                                        <label>Last Name</label>
                                        <input  type="text" 
                                                className='form-control' 
                                                name="lastName" 
                                                value={lastName}
                                                onChange={onInputChange}
                                                placeholder="Enter last Name"  />
                                    </Col>
                                
                                    <Col className="mb-3" md={6}>
                                        <label>Email</label>
                                        <input  type="text" 
                                                disabled
                                                className='form-control' 
                                                name="email" 
                                                value={email}
                                                onChange={onInputChange}
                                                placeholder="Enter email"  />
                                    </Col>
                                    
                                    <Col className="mb-3" md={6}>
                                        <label>Contact no.</label>
                                        <input  type="phone" 
                                                className='form-control' 
                                                name="contact-number" 
                                                value={contactNumber}
                                                onChange={onInputChange}
                                                placeholder="Enter mobile no."  />
                                    </Col>

                                    <Col className="mb-3" md={6}>
                                        <label>Emergency contact no.</label>
                                        <input  type="phone" 
                                                className='form-control' 
                                                name="emergency-contact" 
                                                value={emergenciesContactNumber}
                                                onChange={onInputChange}
                                                placeholder="Enter emergency contact no."  />
                                    </Col>

                                    <Col className="mb-3" md={6}>
                                        <label>Blood Group</label>
                                        <input  type="text" 
                                                className='form-control' 
                                                name="blood-group"
                                                value={blodGroup}
                                                onChange={onInputChange} 
                                                placeholder="Enter Blood Group"  />
                                    </Col>
                                    
                                    <Col className="mb-3" md={6}>
                                        <label>Allergies / Comment</label>
                                        <input  type="text" 
                                                className='form-control' 
                                                name="allergies" 
                                                value={elagiesComent}
                                                onChange={onInputChange}
                                                placeholder="Blood Pressor, Sugar"  />
                                    </Col>

                                    <Col className="mb-3" md={6}>
                                        <label>Gender</label>
                                        <select className="form-control" id="gender" name="gender" onChange={selectedValue}>
                                            {getGenderView()}
                                        </select>
                                    </Col>

                                
                                    <Col className="mb-3" md={6}>
                                        <label>DOB</label>
                                        <input  type="date" 
                                                className='form-control' 
                                                name="personaldob" 
                                                value={dob}
                                                onChange={onDateChangeHandler}
                                                placeholder="DD/MM/YY"  />
                                    </Col>


                                    <Col className="mb-3" md={6}>
                                        <label>Address</label>
                                        <input  type="text" 
                                                className='form-control' 
                                                name="address" 
                                                value={address}
                                                onChange={onInputChange}
                                                placeholder="Enter your address"  />
                                    </Col>
                                    <Col md={12}>
                                        
                                    </Col>
                                </Row> 
                            </Col>
                        </Row>
                    </div>

                    <div className="border-box">
                        <Row className="">
                            <Col xxl={10}>
                                <label>(optional)</label>
                                <h6>Document</h6>
                                <Row>
                                    <Col className="mb-3" md={4}>
                                        <label>Expiry Date</label>
                                        <input 
                                            type="date" 
                                            className='form-control' 
                                            name="docType1expiryData" 
                                            placeholder="DD/MM/YY" 
                                            value={documentOne.ExpiredDate}
                                            onChange={onDateChangeHandler}
                                        />
                                    </Col>
                                    <Col className="mb-3" md={4}>
                                        <label>Select Document Type</label>
                                        <select className="form-control" id="gender" name="docType1" onChange={selectedValue}>
                                        {getDocumentsTypeViewForDocType1()}
                                        </select>
                                    </Col>
                                    <Col className="mb-3" md={4}>
                                    <div className="uploadFile">
                                        <label for="document1">Document 1</label>
                                        <span>
                                            <input  type="file" 
                                                    id="document1" 
                                                    className='form-control' 
                                                    name="documentOne"
                                                    onChange={handleImageUpload}  
                                                    accept=".jpg, .jpeg, .png" />   
                                            <label for="document1" className="upload-label text-disabled">
                                                {documentOne && documentOne.actualFileName !=undefined && documentOne.actualFileName.length >25 ? documentOne.actualFileName.substring(0, 20)+"...": documentOne.actualFileName}
                                            </label>
                                        </span>
                                    </div>
                                    {documentOne.FileName && <button type="button" style={{
                                        background: "rgb(7, 27, 109)",
                                        color: "white",
                                        border: "none",
                                        "padding": "4px",
                                        "border-radius": "8px",  
                                        position: "relative",
                                        left: "230px",
                                        top: "-40px",  
                                    }} onClick={()=>{
                                        if(documentOne && documentOne.FileName !=undefined){
                                            dispatch(LoaderForIndividualPageDispatch(true))
                                            setDocView(()=> ({show: true, url: documentOne.FileName}));
                                            // window.open(documentOne.FileName,'_blank');
                                        }  
                                    }}>View</button>}
                                    </Col>


                                    <Col className="mb-3" md={4}>
                                        <label>Expiry Date</label>
                                        <input 
                                            type="date" 
                                            className='form-control' 
                                            name="docType2expiryData" 
                                            placeholder="DD/MM/YY" 
                                            value={documentTwo.ExpiredDate}
                                            onChange={onDateChangeHandler}
                                        />
                                    </Col>
                                    <Col className="mb-3" md={4}>
                                        <label>Select Document Type</label>
                                        <select className="form-control" id="gender" name="docType2" onChange={selectedValue}>
                                            {getDocumentsTypeViewForDocType2()}
                                        </select>
                                    </Col>
                                    <Col className="mb-3" md={4}>
                                    <div className="uploadFile">
                                        <label for="document2">Document 2</label>
                                        <span>
                                            <input  type="file" 
                                                    id="document2" 
                                                    className='form-control' 
                                                    name="documentTwo"
                                                    onChange={handleImageUpload}  
                                                    accept=".jpg, .jpeg, .png" />   
                                            <label for="document2" className="upload-label text-disabled">
                                                 {documentTwo && documentTwo.actualFileName !=undefined && documentTwo.actualFileName.length >25 ? documentTwo.actualFileName.substring(0, 20)+"...": documentTwo.actualFileName}
                                            </label>
                                        </span>
                                    </div>
                                    {documentTwo.FileName && <button type="button" style={{
                                            background: "rgb(7, 27, 109)",
                                            color: "white",
                                            border: "none",
                                            "padding": "4px",
                                            "border-radius": "8px",  
                                            position: "relative",
                                            left: "230px",
                                            top: "-40px",  
                                    }} onClick={()=>{
                                        if(documentTwo && documentTwo.FileName !=undefined){
                                            dispatch(LoaderForIndividualPageDispatch(true))
                                            setDocView(()=> ({show: true, url: documentTwo.FileName}));
                                            // window.open(documentTwo.FileName,'_blank');
                                        }  
                                    }}>View</button>}
                                    </Col>
                                   
                                   
                                    <Col className="mb-3" md={4}>
                                        <label>Expiry Date</label>
                                        <input 
                                            type="date" 
                                            className='form-control' 
                                            name="docType3expiryData" 
                                            placeholder="DD/MM/YY" 
                                            value={documentThree.ExpiredDate}
                                            onChange={onDateChangeHandler}
                                        />
                                    </Col>
                                    <Col className="mb-3" md={4}>
                                        <label>Select Document Type</label>
                                        <select className="form-control" id="gender" name="docType3" onChange={selectedValue}>
                                            {getDocumentsTypeViewForDocType3()}
                                        </select>
                                    </Col>
                                    <Col className="mb-3" md={4}>
                                    <div className="uploadFile">
                                        <label for="document3">Document 3</label>
                                        <span>
                                            <input  type="file" 
                                                    id="document3" 
                                                    className='form-control' 
                                                    name="documentThree"
                                                    onChange={handleImageUpload}  
                                                    accept=".jpg, .jpeg, .png"  />   
                                            <label for="document3" className="upload-label text-disabled">
                                                

                                            {documentThree && documentThree.actualFileName !=undefined && documentThree.actualFileName.length >21 ? documentThree.actualFileName.substring(0, 20)+"...": documentThree.actualFileName}
                                            </label>
                                        </span>
                                    </div>

                                    {documentThree.FileName && <button type="button" style={{
                                            background: "rgb(7, 27, 109)",
                                            color: "white",
                                            border: "none",
                                            "padding": "4px",
                                            "border-radius": "8px",  
                                            position: "relative",
                                            left: "230px",
                                            top: "-40px",  
                                    }} onClick={()=>{
                                        if(documentThree && documentThree.FileName !=undefined){
                                            dispatch(LoaderForIndividualPageDispatch(true))
                                            setDocView(()=> ({show: true, url: documentThree.FileName}));
                                            // window.open(documentThree.FileName,'_blank');
                                        }  
                                    }}>View</button>}

                                    </Col>

                                </Row> 
                            </Col>
                        </Row>
                    </div>

                    <div className="border-box">
                        <h6>DND</h6>
                        <Row>
                            <Col className="mt-2" md={12}>
                                <FormGroup switch className="ps-0">
                                    <Label for="call" className="text-disabled" check>Call Me</Label>
                                    <Input
                                        type="switch"
                                        className="float-end"
                                        id="call"
                                        checked={DNDcall}
                                        onClick={async(e) =>{
                                            try{
                                                let callDndData={
                                                    DND: !DNDcall,
                                                    QRId: props.tagData.QRNumber,
                                                    ForValue: 1
                                                }
                                                await postSaveDND(callDndData, dispatch); 
                                            }catch (err){
                                                if(err!=undefined && err.Error!= undefined && err.Error) toast.error(err.Error.Message, { });
                                                
                                            }
                                            setDNDcall((pre)=>{
                                                return !pre
                                            });
                                        }}
                                    />
                                </FormGroup>
                            </Col>

                            <Col className="mt-2" md={12}>
                                <FormGroup switch className="ps-0">
                                    <Label for="emergencycall" className="text-disabled" check>Emergency Call</Label>
                                    <Input
                                        type="switch"
                                        className="float-end"
                                        id="emergencycall"
                                        checked={DNDemergencycall}
                                        onClick={async() =>{
                                                try{
                                                    let callEmergencyData={
                                                        DND: !DNDemergencycall,
                                                        QRId: props.tagData.QRNumber,
                                                        ForValue: 4
                                                    }
                                                    await postSaveDND(callEmergencyData, dispatch); 
                                                }catch (err){
                                                    if(err!=undefined && err.Error!= undefined && err.Error) toast.error(err.Error.Message, { });
                                                    
                                                }
                                            setDNDemergencycall(!DNDemergencycall)
                                        }}
                                    />
                                </FormGroup>
                            </Col>

                            {/* <Col className="mt-2" md={12}>
                                <FormGroup switch className="ps-0">
                                    <Label for="Medical_History" className="text-disabled" check>Medical History</Label>
                                    <Input
                                        type="switch"
                                        className="float-end"
                                        id="Medical_History"
                                        checked={DNDMedicalHistory}
                                        onClick={async() =>{
                                            try{
                                                let callWhatsAppData={
                                                    DND: !DNDMedicalHistory,
                                                    QRId: props.tagData.QRNumber,
                                                    ForValue: 6
                                                }
                                                await postSaveDND(callWhatsAppData, dispatch); 
                                            }catch (err){
                                                if(err!=undefined && err.Error!= undefined && err.Error) toast.error(err.Error.Message, { });
                                                
                                            }
                                            setDNDmedicalHistory(!DNDMedicalHistory)
                                        }}
                                    />
                                </FormGroup>
                            </Col> */}

                            {/* <Col className="mt-2" md={12}>
                                <FormGroup switch className="ps-0">
                                    <Label for="whatsapp" className="text-disabled" check>WhatsApp/SMS</Label>
                                    <Input
                                        type="switch"
                                        className="float-end"
                                        id="whatsapp"
                                        checked={DNDwhatsapp}
                                        onClick={async() =>{
                                            try{
                                                let callWhatsAppData={
                                                    DND: !DNDwhatsapp,
                                                    QRId: props.tagData.QRNumber,
                                                    ForValue: 2
                                                }
                                                await postSaveDND(callWhatsAppData, dispatch); 
                                            }catch (err){
                                                if(err!=undefined && err.Error!= undefined && err.Error) toast.error(err.Error.Message, { });
                                                
                                            }
                                            setDNDwhatsapp(!DNDwhatsapp)
                                        }}
                                    />
                                </FormGroup>
                            </Col> */}
                            <Col className="mt-2" md={12}>
                                <FormGroup switch className="ps-0">
                                    <Label for="email" className="text-disabled" check>Email</Label>
                                    <Input
                                        type="switch"
                                        className="float-end"
                                        id="email"
                                        checked={DNDemail}
                                        onClick={async() =>{
                                            try{
                                                let callEmailData={
                                                    DND: !DNDemail,
                                                    QRId: props.tagData.QRNumber,
                                                    ForValue: 3
                                                }
                                                await postSaveDND(callEmailData, dispatch); 
                                            }catch (err){
                                                if(err!=undefined && err.Error!= undefined && err.Error) toast.error(err.Error.Message, { });
                                            }
                                            setDNDemail(!DNDemail)
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row> 
                    </div>

                    <div className="border-box" id='location-summary'>
                        <h6>LAST SCAN</h6>
                        <Row>
                            <Col className="mt-2" md={12}>
                                <div className="trackLists text-disabled pt-4">
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>Date	</th>
                                                <th>Time</th>
                                                <th>Location</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {getLocaionListView()}
                                        </tbody>
                                    </Table>
                                    
                                </div>
                            </Col>
                        </Row> 
                    </div>

                    <div className="mb-2 pt-3">
                        <button type="submit" className="me-3 btn btn-primary btn-long">Save</button>
                        <button type="button" onClick={()=>props.onSetIsEdit()} className=" btn btn-outline-secondary btn-long">Cancel</button>
                    </div>
                </form>
            </div>
        </React.Fragment>
    );
}
  
export default EditTag;