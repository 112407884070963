import React, { useEffect, useState, useContext } from "react";
import { useSelector, useDispatch } from 'react-redux'
// import { loginDispatch, setSessionData } from "./slice.reducer";
import { useNavigate } from 'react-router-dom'
import { NavLink, useLocation } from "react-router-dom";

import LoginSlider from './../../../Components/LoginSlider';
import {Container, Row, Col } from 'reactstrap'
 import Eye from './../../../Assets/Images/eye.png';
 import Logo from './../../../Assets/Images/logo.png';
import EyeOff from './../../../Assets/Images/eye-off.png';

import validator from 'validator'; 

import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { loginDispatch, setAuth, setIsLogedIn as setIsLogedInSlice } from "./slice.reducer";
import { checkMobileWebView } from "../../../utility/helper";
// import { fetchUserDetailsDispatch } from "../../Profile/slice.reducer.tsx";
// import { setToggleForExpandAndColleps } from "../../../COMPONENTS/SideBarMenu/activeParentModule.slice";
// import CreateTokenContextProvider from "../../../STORE/App-wide-state/Token/createTokenContextProvider";
// import { LoaderForIndividualPageDispatch } from "../../../COMPONENTS/Loader/LoaderForIndividualPage.slice.reducer.js";


function Login() {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isLogedIn, setIsLogedIn] = useState(false)
  const [isLoginButtonClicked, setisLoginButtonClicked] = useState(false)
  const auth = useSelector(state => state.auth);
  // console.log('this is our login submit data', auth)
  const [isPasswordMaskedOPen, setIsPasswordMaskedOPen] = useState(true)
  const [emailErr, setEmailErr] = useState(false)
  const [passwordErr, setPasswordErr] = useState(false)
  const location = useLocation();
  const {state} = useLocation();
  // const tokenContext = useContext(CreateTokenContextProvider);

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])

  const onInputChange = (e)=>{
    
    switch(e.target.name){
      case "email":
        setEmail(e.target.value);
        setEmailErr(false)
        break;
      case "password":
        setPassword(e.target.value);
        setPasswordErr(false)
        break;
      default:
        setPassword(e.target.value);
        break;
    }
  }

  const login = (e)=>{
  
    toast.dismiss()
    e.preventDefault();
    toast.dismiss()
    
    if(!validator.isEmail(email.trim())){
      setEmailErr(true)
      if(email === "") toast.error("Please Enter Email", { });
      else toast.error("Please Enter valid Email", { });
      setEmailErr(true)
      return
    }
    
    if(!validator.isLength(password, {min: 8, max: 14})){
      // setPasswordErr(true)
      if(password === "") toast.error("Please Enter Password", { });
      else toast.error("Password length should be minimum 8 char and maximum 14 char", { });
      setPasswordErr(true)
      return;
    }
    
    dispatch(loginDispatch({email: email.trim(), password: password})); 

  }

    const navigateToForgetPassword = () =>{
      navigate('/forgot-password');
    }

    // AFTER RELOAD, if local-storage not emply 
    useEffect(()=>{
      
      async function setStoreData(){
        // console.log("this is a login useEffect", auth)
        
        if(auth.data === null){
          const data = await JSON.parse(localStorage.getItem('auth'))
          if( data !== null ){
            console.log("this is a login useEffect",data)
            // set auth slice with localStorage data
            dispatch(setAuth(data))
            dispatch(setIsLogedInSlice(true))
            setIsLogedIn(true);

            // still yet to update context api also
          }
        }else if(auth.isLogedIn && auth.data !== null){
          setIsLogedIn(true);
        }else if(!auth.isLogedIn){
          setIsLogedIn(false);  
        }else{ 
          setIsLogedIn(false);  
        }
      }
  
      setStoreData();
    },[ auth.data, auth.isLogedIn, dispatch ]);
  

  if(isLogedIn){
    navigate('/');
  }

  return (
  <React.Fragment>
     <div className="container-fluid p-0 signup-page">
        <Row className="m-0">
          <Col className="p-0" lg={6}>
            <LoginSlider />
          </Col>
          <Col className="pt-3 signupScreen" lg={6} data-aos="zoom-in-up" data-aos-delay="200" data-aos-duration="200">
          
              <div className="signup-wrap">
                {/* Logo  */}
                <div className="text-center mb-3 mt-3">
                                              <a 
                                // href= {checkMobileWebView()? "#" :"/"}
                                // onClick={(e)=>{
                                //     if(checkMobileWebView()){
                                //         e.preventDefault();
                                //         window.ReactNativeWebView.postMessage('formSubmitted');
                                //     }
                                // }}
                                href= {"/"}
                            ><img className="mb-4 pb-1" src={Logo} alt="" /></a>
                  <h4>Corporate Login</h4>
                </div>

                <form className="pb-5 mb-2 mb-md-0 pt-1" onSubmit={login}>
                  <div className="mb-3">
                      <input type={'text'} className={'form-control'} name={'email'} placeholder="Email" value={email}
                          onChange={onInputChange}  />
                  </div>
                  <div className="mb-1 password-field">
                      <input type={isPasswordMaskedOPen ?'password': "Text"} name={'password'} placeholder="Password" value={password}
                          onChange={onInputChange} className={'form-control'} />
                      <span onClick={()=>setIsPasswordMaskedOPen(!isPasswordMaskedOPen)}>
                          <img src={isPasswordMaskedOPen ?Eye: EyeOff} alt="Eye" />
                          {/* <img src={EyeOff} className="d-none" alt="Eye" /> */}
                      </span>
                  </div>
                  <div className="text-end mb-3">
                      <a className="secondary text-dark" onClick={(e)=>{
                        e.preventDefault();
                        navigateToForgetPassword()
                      }
                       }>Forgot Password?</a>
                  </div>
                    <button type="submit"  className="btn btn-primary w-100" >Login</button>
                  
                  <div className="text-center mt-4 mt-lg-5">
                      Don't have an account? <NavLink to="/signup"><u>Sign Up</u></NavLink>
                  </div>
                </form>
                
              </div>
              {/* copyright content  */}
              <div class="copyright text-center p-3"><p class="m-0">Copyright 2024. © Intello Tags All Rights Reserved</p></div>
          </Col>
        </Row>
      </div>
  </React.Fragment>
  );
}
  
  export default Login;