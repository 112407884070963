import React, {useEffect} from "react";
import TagImg from './../../../../Assets/Images/tag-activated.png'
import {Container, Row, Col } from 'reactstrap';
import Logo from './../../../../Assets/Images/logo.png';
import WelcomeAnimation from "../../../../Components/WelcomeAnimation";
import { checkMobileWebView } from "../../../../utility/helper";

function Cart() {

    useEffect(()=>{
        window.scrollTo(0, 0);
    }, []);
    
    return (
    <React.Fragment>
        <main className="bg-light qr-screen h-100vh">
            <header className="header  px-3 px-sm-4">    
                <div className="container">
                    <nav class="fixed-top1 navbar-expand-lg navbar navbar-light bg-faded">
                                                    <a 
                                // href= {checkMobileWebView()? "#" :"/"}
                                // onClick={(e)=>{
                                //     if(checkMobileWebView()){
                                //         e.preventDefault();
                                //         window.ReactNativeWebView.postMessage('formSubmitted');
                                //     }
                                // }}
                                href= {"/"}
                            >
                            <img src={Logo} alt="Logo" className="img-fluid mt-0" />
                        </a>
                    </nav>
                </div>
            </header>
            <section className="animation-parent">
                <Container>
                    <Row className="justify-content-center text-center">
                        <Col xl={6}>
                            <div className="mb-2 text-primary text-uppercase">
                                <h6 className="light-title">Welcome to</h6>
                                <h3>Intello Tags</h3>
                            </div>
                            <div className="tagActivated">
                                <img src={TagImg} className="img-fluid" alt="Tag" />
                            </div>
                            <div className="mt-4 pt-3">
                                <h5 className="font-medium text-primary">Tag Activated<br/>Successfully</h5>
                            </div>
                            
                        </Col>
                    </Row>
                </Container>
                <WelcomeAnimation />
            </section>
            
        </main>
    </React.Fragment>
    );
}
  
  export default Cart;