import React, {useState} from "react";
import { Row, Col,Container,Breadcrumb, BreadcrumbItem  } from 'reactstrap';
import BecomeFranchiseImg from './../../Assets/Images/franchise.png';
import  HeaderFooter from './../../HeaderFooter';
import { sendCommanEmail } from "../../utility/service";
import validator from 'validator';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { checkMobileWebView } from "../../utility/helper";

function BecomeFranchise() {
    
    const dispatch = useDispatch()
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [mobileNumber, setMobileNumber] = useState("")
    const [query, setquery] = useState("")
        
    const onInputChange = (e)=>{
    
        switch(e.target.name){
          case "firstName":
            setFirstName(e.target.value.replace(/[^a-z]/gi, ''));
            break;
          case "lastName":
            setLastName(e.target.value.replace(/[^a-z]/gi, ''));
            break;
          case "email":
            setEmail(e.target.value);
            break;
        case "mobileNumber":
            if(/^(?:\+?\d{0,15}|\d{0,16})$/.test((e.target.value))){
                setMobileNumber(e.target.value);
            }
            break;
        case "query":
            setquery(e.target.value);
            break;
    }
    }

    const applySendEmail = async (e)=>{
   
    
        e.preventDefault();
        toast.dismiss()
       
        if(validator.isEmpty(firstName.trim())){
          toast.error("Please Enter First Name", { });
          return;
        }else if(!validator.isLength(firstName.trim(), {min: 1, max: 25})){
          toast.error("First Name length should be minimum 3 char and maximum 25 char", { });
          return;
        }
       
        if(validator.isEmpty(lastName.trim())){
          toast.error("Please Enter Last Name", { });
          return;
        }else if(!validator.isLength(lastName.trim(), {min: 1, max: 25})){
          toast.error("Last Name length should be minimum 3 char and maximum 25 char", { });
          return;
        }
    
        if(!validator.isEmail(email, {blacklisted_chars : "+-"})){
          if(email === "") toast.error("Please Enter Email", { });
          else toast.error("Please Enter valid Email", { });
          return
        }
        
        if(validator.isEmpty(mobileNumber)){
            toast.error("Please Enter Phone Number", { });
            return;
        }

        if(validator.isEmpty(query.trim())){
            toast.error("Please Enter query Name", { });
            return;
        }
        else if(!validator.isLength(query.trim(), {min: 5})){
            toast.error("Query length should be minimum 5 char", { });
            return;
        }
    
        let sendEmailData = {
            FullName: firstName.trim() + lastName.trim()? ` ${lastName.trim()}`: "", 
            UserEmail: email,
            Mobile: mobileNumber,
            Subject: "Become a Franchise",
            Query: query,
            EmailFor: 3,
        } 
    
        try{
            await sendCommanEmail(sendEmailData, dispatch); 
            toast("Email Sent Successfully", { });
            setFirstName("");
            setLastName("");
            setEmail("");
            setMobileNumber("");
            setquery("");
        }catch (err){
          if(err!=undefined && err.Error!= undefined && err.Error) toast.error(err.Error.Message, { });
          
        }
    }
  
    return (
      <React.Fragment>
          <HeaderFooter>
           <div data-aos="zoom-in" data-aos-delay="200" data-aos-duration="200">
                <img src={BecomeFranchiseImg} className="w-100" alt="BecomeFranchise" />
           </div>

           <Container>
                <div className="my-4 pt-0 pt-md-4">
                    
                    <Row className="justify-content-center">
                        <Col lg={10} xxl={8}>
                            <Breadcrumb> 
                                <BreadcrumbItem>                            <a 
                                // href= {checkMobileWebView()? "#" :"/"}
                                // onClick={(e)=>{
                                //     if(checkMobileWebView()){
                                //         e.preventDefault();
                                //         window.ReactNativeWebView.postMessage('formSubmitted');
                                //     }
                                // }}
                                href= {"/"}
                            >Home</a></BreadcrumbItem>
                                <BreadcrumbItem active>Become a Franchise</BreadcrumbItem>
                            </Breadcrumb>
                        </Col>
                    </Row>
                </div>
           </Container>
          
           <div className=" main-content">
          
            <Container>
                <div data-aos="zoom-in" data-aos-delay="200" data-aos-duration="200">
                    
                    <Row className="justify-content-center">
                        <Col lg={10} xxl={8}>
                            <h3 className="text-center text-black mb-4">Become a Franchise</h3>
                            <div className="white-box borderd">
                            <form className="p-1 p-md-3" onSubmit={applySendEmail}>
                                <Row className="justify-content-center">
                                    <Col md={6}>
                                        <div className="mb-3">
                                            <label className="form-label">First Name</label>
                                            <input 
                                                type="text" 
                                                className={'form-control'}
                                                placeholder="Enter your first name" 

                                                value={firstName} 
                                                onChange={onInputChange} 
                                                name="firstName"
                                            />
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="mb-3">
                                            <label className="form-label">Last Name</label>
                                            <input  
                                                type="text" 
                                                className={'form-control'} 
                                                placeholder="Enter your last name" 

                                                value={lastName} 
                                                onChange={onInputChange} 
                                                name="lastName"
                                            />
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="mb-3">
                                            <label className="form-label">Email</label>
                                            <input 
                                                type="text" 
                                                className={'form-control'}
                                                placeholder="Enter your email"

                                                value={email} 
                                                onChange={onInputChange} 
                                                name="email"
                                            />
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="mb-3">
                                            <label className="form-label">Mobile</label>
                                            
                                            <input
                                                type="text" 
                                                className="form-control"
                                                placeholder="Enter your mobile number" 

                                                value={mobileNumber} 
                                                onChange={onInputChange} 
                                                name="mobileNumber"
                                                
                                            />
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        <div className="mb-3">
                                            <label className="form-label">Query</label>
                                            
                                            <textarea
                                                rows={5}
                                                type="text" 
                                                className="form-control"
                                                placeholder="Enter your query" 

                                                value={query} 
                                                onChange={onInputChange} 
                                                name="query"
                                            />
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        <div className="mb-1 mt-4">
                                            <button type="submit" className="btn btn-primary me-3" >Submit</button>
                                        </div>
                                    </Col>
                                    </Row>
                                </form>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
            </div>
            </HeaderFooter>
      </React.Fragment>
    );
  }
  export default BecomeFranchise;